import { css } from "@emotion/react"
import styled from "@emotion/styled"

const snipcart = theme => css`
  .snipcart * {
    font-family: ${theme.fonts.primaryFont} !important;
  }

  .snipcart-cart__secondary-header {
    background-color: ${theme.palette.primary.main} !important;
    .snipcart__icon--darker path {
      fill: white;
    }
    .snipcart-cart__secondary-header-title {
      color: white;
    }
  }

  .snipcart-cart-button,
  .snipcart__box--badge-highlight {
    background-color: ${theme.palette.primary.main} !important;
    background-image: none !important;
  }

  .snipcart-cart-button--secondary {
    .snipcart__icon path {
      fill: white !important;
    }
    color: white !important;
  }

  .snipcart__icon--blue-light path,
  .snipcart__icon--blue-dark path {
    fill: ${theme.palette.primary.main} !important;
  }

  .snipcart__actions--link,
  .snipcart-discount-box__submit {
    color: ${theme.palette.primary.main} !important;
  }

  .snipcart-discount-box__form,
  .snipcart-input:focus-within {
    border: 1px solid ${theme.palette.primary.main} !important;
  }

  .snipcart-overwrite #snipcart-footer #snipcart-powered {
    display: none !important;
  }
`

const wordpress = css`
  // WORDPRESS CLASSES
  /* =WordPress Core
  -------------------------------------------------------------- */
  .alignnone {
    margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }

  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  a img.alignnone {
    margin: 5px 20px 20px 0;
  }

  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }

  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }

  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
`

export const mapMarkers = css`
  .tooltip-inside-map {
    font-size: 1rem;
    line-height: 24px;
    background: #2e558a;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    color: white;
    position: absolute;
    bottom: 40px;
    left: 0;
    min-width: 260px;
    display: none;
  }
  .tooltip-inside-map p {
    margin: 0;
  }
  .marker-svg {
    width: 25px;
    height: 25px;
    background: radial-gradient(#2e558a 20%, white 25%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 15px #2e558a;
  }
  .marker-svg:hover {
    background: #2e558a;
  }
  .tooltip-wrapper:hover .tooltip-inside-map {
    display: block;
  }
  .tooltip-wrapper:hover .marker-svg {
    background: #2e558a;
  }
`

export const GlobalStyles = theme => css`
  // * {
  //    color: ${theme.palette.text.content};
  // }
  img,
  svg {
    max-width: 100%;
  }

  .emphasis {
    color: ${theme.palette.primary.main};
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;

    ${theme.breakpoints.up("md")} {
      font-size: 2.5rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${theme.fonts.secondary};
    margin: 0;
  }

  .tl-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  ${wordpress}
  ${snipcart}
  ${mapMarkers}

  .cky-consent-container {
    z-index: 9999999999 !important;
  }
`

export const LayoutTitle = styled.h2`
  background-color: yellow;
  color: black;
  font-size: 2.5rem;
  font-style: italic;
  text-align: center;
`

export const AppContainer = styled.main`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.navHeight}px;
  //background-color: #f8fdff;
`

export const TopContainer = styled.div`
  ${({ gradient }) =>
    (gradient === "home" &&
      `background: linear-gradient(180deg, #2E558A 50.52%, #FFFFFF 87.5%);`) ||
    (gradient === "about" &&
      `background: linear-gradient(0deg, #2E558A 20.52%, #FFFFFF 87.5%);`) ||
    (gradient === "news" &&
      `background: linear-gradient(0deg, #FFFFFF 73%, #2E558A 100%);`) ||
    (gradient === "pipeline" &&
      `background: linear-gradient(180deg, #2E558A 0%, #FFFFFF 100%);`) ||
    (gradient === "team" &&
      `background: linear-gradient(180deg, #2E558A 0%, #FFFFFF 57.5%);`) ||
    (gradient === "case-studies" &&
      `linear-gradient(360deg, #2E558A 0.35%, rgba(49, 88, 140, 0.96) 19.12%, rgba(52, 90, 141, 0.8) 35.11%, #ECF0F5 59.2%, #FFFFFF 74.63%, #FFFFFF 100%)`) ||
    (gradient === "no-gradient" && `background: white`)}
`
