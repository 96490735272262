import React, {useEffect} from "react"
import Tracker from "@openreplay/tracker"
import { Global } from "@emotion/react"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout"

import { useMediaQuery } from "@mui/material"
import { GlobalStyles } from "../../styles/app.styles"

export default function TopLayout({ children, theme }) {
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  let navHeight = 74
  let sectionPadding = theme.sectionPadding

  if (isMd) {
    navHeight = theme.navHeight
    sectionPadding = "padding-top: 5rem; padding-bottom: 5rem;"
  }

  useEffect(() => {
    if (process.env.GATSBY_ACTIVE_ENV === "production") {
      const tracker = new Tracker({
        projectKey: "IkZ6ZQbbC5aCJG4xmgML",
        ingestPoint: "https://openreplay.aftershock.agency/ingest",
      })
      tracker.start()
    }
  }, [])

  return (
    <ThemeTopLayout theme={{ ...theme, navHeight, sectionPadding }}>
      <Global styles={GlobalStyles} />
      {children}
    </ThemeTopLayout>
  )
}
